import * as React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import Navbar from '../components/navbar';
import HeroInner from '../components/hero-inner';
import BlogContent from '../components/blog-content';
import SIDEBAR from '../components/s-i-d-e-b-a-r';
import Footer from '../components/footer';

const BlogPost = styled.section`
	align-self: stretch;
	background-color: var(--dominant);
	box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: var(--padding-71xl) var(--padding-101xl);
	gap: var(--gap-81xl);
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;
const BlogRoot = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	font-size: var(--font-size-2xl);
	color: var(--dominant);
	// font-family: var(--text-tiny-link);
`;
const BlogOrderedList = styled.ol`
	margin: 0;
	font-size: inherit;
	padding-left: var(--padding-5xl);
	margin-block-end: 1.875rem;
`;
const BlogListItem = styled.li`
	margin-bottom: 12px;
	b {
		display: flex;
	}
`;

const Trends = () => {
	return (
		<BlogRoot>
			<Navbar
				continLogo2a1='/continlogo2a-1.svg'
				accountSection={false}
				hamburgerMenu='/notification.svg'
				showHamburgerMenuIcon
				notificationBell='/notification1.svg'
				profilePicture='/top_avatar@2x.png'
				actionButton='Get in Touch'
				iconChevronRight='/icon--chevronright@2x.png'
				showActionButton={false}
				showActionButtonLink={false}
			/>
			<HeroInner
				headerTitle={`The Future of Compliance Technology: Trends to Watch`}
				tagDate='Culture  -  Mar 5, 2024'
				headerImage='/AS_130931875_LIC-HipsterTrends.jpeg'
			/>
			<BlogPost>
				<BlogContent
					introKicker={
						'In an era defined by rapid technological advancements and an evolving regulatory landscape, the realm of compliance is undergoing a profound transformation. As organizations navigate the complexities of meeting regulatory requirements, a new wave of compliance technologies is emerging, promising to reshape the way businesses approach and implement compliance strategies.'
					}
					pullQuote='Join us as we explore the trends that are set to define the future of compliance technology.'
					mainContent={
						<>
							<Typography variant='h3' sx={{ fontFamily: 'Oswald' }}>
								The Cultural Imperative.
							</Typography>
							<BlogOrderedList>
								<BlogListItem>
									<b>Artificial Intelligence and Machine Learning Integration:</b>
									Trend: The infusion of artificial intelligence (AI) and machine
									learning (ML) into compliance processes for enhanced data analysis,
									anomaly detection, and predictive risk assessments. Impact:
									Streamlining compliance tasks, automating risk identification, and
									providing actionable insights for proactive decision-making.
								</BlogListItem>
								<BlogListItem>
									<b>Blockchain for Enhanced Security:</b>
									Trend: The adoption of blockchain technology to secure and authenticate
									compliance-related data, ensuring transparency and immutability.
									Impact: Reducing fraud risks, enhancing data integrity, and simplifying
									audit trails for regulatory reporting.
								</BlogListItem>
								<BlogListItem>
									<b>RegTech Advancements:</b>
									Trend: The continual evolution of Regulatory Technology (RegTech)
									solutions, leveraging automation and analytics to facilitate compliance
									management. Impact: Improving efficiency, accuracy, and agility in
									compliance processes, enabling organizations to adapt to regulatory
									changes swiftly.
								</BlogListItem>

								<BlogListItem>
									<b>Continuous Monitoring Solutions:</b>
									Trend: The rise of continuous monitoring tools that provide real-time
									insights into compliance status, enabling immediate response to
									potential issues. Impact: Minimizing the time gap between
									non-compliance detection and remediation, ensuring a proactive
									compliance posture.
								</BlogListItem>
								<BlogListItem>
									<b>Cloud-Based Compliance Platforms:</b>
									Trend: The migration toward cloud-based compliance platforms, offering
									scalability, flexibility, and seamless collaboration. Impact:
									Facilitating remote work, centralizing compliance data, and reducing
									infrastructure costs.
								</BlogListItem>
								<BlogListItem>
									<b>Predictive Analytics for Risk Management:</b>
									Trend: The utilization of predictive analytics to assess and mitigate
									compliance risks before they escalate. Impact: Enhancing risk
									management strategies, enabling organizations to foresee and address
									potential compliance challenges.
								</BlogListItem>
							</BlogOrderedList>
							<Typography
								component='h3'
								variant='h3'
								sx={{ fontFamily: 'Oswald', marginBlockEnd: '1rem' }}
							>
								Conclusion
							</Typography>
							<Typography component='p' variant='p'>
								As we step into the future, the integration of advanced technologies
								into compliance strategies is not merely an option but a necessity. By
								staying ahead of these trends, organizations can proactively adapt to
								the changing regulatory landscape, fortify their compliance programs,
								and position themselves for sustained success.
							</Typography>
							<Typography component='p' variant='p'>
								<b>Next Steps:</b>
								Stay tuned for in-depth explorations of each of these trends in our
								upcoming blog series. Learn how your organization can harness the power
								of emerging technologies to revolutionize your approach to compliance.
							</Typography>
						</>
					}
				/>
				<SIDEBAR />
			</BlogPost>

			<Footer />
		</BlogRoot>
	);
};

export default Trends;
